import React, {useState, useEffect, useRef} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import AutoNotification from "../layouts/AutoNotification";
import Section from "../layouts/Section";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header} from '../../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money} from '../../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import Lottie from "lottie-react";
import animation1 from "./mine1.json";
import animation2 from "./mine2.json";
import equalizer from "./equalizer2.json";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { EffectCube, Pagination } from 'swiper/modules';
import { AiOutlineHistory } from "react-icons/ai";
import "./styles.css";
import DownModal from "../layouts/DownModal";
import {CountdownWithGrowth} from "./CountdownWithGrowth";


const tradeBTN = require('../../images/trade_button_bg.jpg');
  



function Minery() {

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [tradeAmount, setTradeAmount]   = useState('');
    const [openAmountPanel, setOpenAmountPanel]   = useState(false);
    const [whichBuyIndex, setWhichBuyIndex]   = useState(null);
    
    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState({});
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [lastClickedIndex, setLastClickedIndex]   = useState(null);
    
    const planonebtn = useRef(null);
    const plantwobtn = useRef(null);

    const dispatch = useDispatch();

    const toggleAccountPanel = () => {
      setOpenAmountPanel(pann => !pann)
    }


    const loadAllTrades = () => {

      dispatch(
        tradeActions.onGetUserTrades("trade/all", response => {
            
            
        }),
      )

    }

    const tradeNow = () => {

      if(isNaN(tradeAmount) || (tradeAmount <= 0)){
          return;
      }

      setloading(true);
      setLoadingText('Searching for appropriate trade entry...');

      const runObj = {amount:tradeAmount, planId: whichBuyIndex, currency: currencySelected?.type}

      dispatch(tradeActions.onPOSTTheTrades(runObj, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    toggleAccountPanel()
                    loadAllTrades()
                }
                
            }),
        )

    }


    const toggleTheCurrency = () => {
      let newCurrency = finance?.filter(eachCurr => eachCurr.abbrRo != currencySelected.abbrRo)
      setCurrencySelected(newCurrency?.[0])
    }

    const editIcon = <>
    
        <div className="cursor-pointer" style={{marginRight: 10}}>

          <div onClick={toggleTheCurrency}>
              <span style={{fontSize: 10, marginRight: 10}}>Change </span> 
              <Images src={currencySelected?.image} className="fa fa-spin" style={{width: 22, height: 22}} />
          </div>

        </div>

        <WebLink to={'/withdrawal-records'} className="" style={{marginRight: 10}}>
            <AiOutlineHistory size={20} />
        </WebLink>

      </>

    
    const activeAndPendingHistories = tradesData?.games?.filter(eachHis => eachHis.isProcessed == 0)

    let allPlans = [
      {
        plan: 'a', 
        lid: 1,
        title: 'Ant Miner',
        hashrate: '255',
        power: 5304,
        recommended: true,
      }, 
      {
        plan: 'b', 
        lid: 2,
        title: 'MicroBT',
        hashrate: '116',
        power: 804,
        recommended: false,
      }
    ]

    
    useEffect(() => {

      if(activeAndPendingHistories?.length > 0){
        let pendingCurrency = activeAndPendingHistories?.[0]?.type
        let getPendingCurrencyObject = finance?.filter(eachCur => eachCur.type == pendingCurrency)

console.log('getPendingCurrencyObject?.[0]?.planIndex', activeAndPendingHistories?.[0]?.planIndex)
        if((lastClickedIndex != activeAndPendingHistories?.[0]?.planIndex) && (activeAndPendingHistories?.[0]?.planIndex != 1)){
          setLastClickedIndex(activeAndPendingHistories?.[0]?.planIndex)
          if(activeAndPendingHistories?.[0]?.planIndex == 1){
            planonebtn.current.click();
          }else{
            plantwobtn.current.click();
          }
        }
        
        setCurrencySelected(getPendingCurrencyObject?.[0])

      }

    }, [activeAndPendingHistories])


    const buyTradingMachine = (planSelected) => {
      setWhichBuyIndex(planSelected)
      toggleAccountPanel()
    }


    


    useEffect(() => {
      loadAllTrades();

      const intervalId = setInterval(() => {
        loadAllTrades();
      }, 2000);

      return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs only once when the component mounts


    return (
        <SiteWide additionalTopIcons={activeAndPendingHistories?.length == 0?editIcon:null} id="main" title={"Mine"}>
         
          <div class="section over-hide">
              <div class="container">
                  <div class="row full-height justify-content-center">
                      <div class="col-12 text-center align-self-center py-5">
                          <div class="section text-center py-5 py-md-0">
                              
                            <input class="pricing" type="checkbox" id="pricing" name="pricing" />
                            

                            <label for="pricing" style={activeAndPendingHistories?.length > 0?{display: 'none'}:{}}>
                                <span class="block-diff">
                                  <span ref={planonebtn}>
                                    {allPlans?.[0]?.title}
                                  </span>
                                  <span ref={plantwobtn} class="float-right">{allPlans?.[1]?.title}</span>
                                  <img style={{width: 30, position: 'absolute', top: -8, left: 6, zIndex: 999999999999999999999}} src={require('./award.png')} />
                                </span>
                            </label>



                            <div class="kard-3d-wrap mx-auto">
                              <div class="kard-3d-wrapper">
                              
                                {allPlans?.map((eachItem, indexi) => {

                                  const activeHistories = tradesData?.games?.filter(eachHis => eachHis.isProcessed == 0 && eachHis.type == currencySelected?.type && eachHis.planIndex == eachItem.lid )
                                  const grabObj = activeHistories?.[0]
                                  const isPlanActive = activeHistories?.length > 0
                                  
                                  return <div class={`kard-${eachItem.lid == 1?'front':'back'}`}>
                                    <div class="pricing-wrap">
                                      <h4 class="mb-5">{eachItem?.title}</h4>
                                      <h2 class="mb-2">{eachItem?.hashrate}<sup>TH/s</sup></h2>
                                      <p class="mb-4">{eachItem?.power}<sup>watt</sup></p>
                                      <p class="mb-1"><i class="uil uil-location-pin-alt size-22"></i></p>

                                      {isPlanActive && <div style={{position: 'absolute', top: 10, right: 10, color: '#000'}} className="text-"><Countdown renderer={({hours, minutes, seconds}) => <span>{hours}:{minutes}:{seconds}</span>} date={grabObj?.timeToMatured} /></div>}

                                      {eachItem?.recommended && !isPlanActive && <p class="mb-4 text-success">Recommended</p>}
                                      {!eachItem?.recommended && !isPlanActive && <p class="mb-4 text-danger">Not Recommended</p>}
                                      {isPlanActive && <p class="mb-4 text-success">Mining Active</p>}
                                      
                                      {!isPlanActive && <span onClick={() => !isPlanActive && buyTradingMachine(eachItem.lid)} style={{backgroundColor: isPlanActive?'#caf7b7':'#000', color: isPlanActive?'#000':'#fff'}} class="link cursor-pointer">{isPlanActive?'Active':'Buy'}</span>}

                                      <div class={`img-wrap img-${eachItem.lid == 1?'1':'4'}`}>
                                        <Lottie options={{autoplay: false}} isStopped={true} isPaused={true} width={20}  height={20} animationData={eachItem.lid == 1?animation1: animation2} loop={isPlanActive?true:false} /> 
                                      </div>
                                      
                                      <div style={{marginTop: 10, paddingRight: 10, paddingLeft: 10}}>
                                        {isPlanActive && <div>

                                          <div><Lottie animationData={equalizer} loop={isPlanActive} /></div>

                                          <div className="flexify" style={{justifyContent: 'space-between'}}>
                                            
                                            <h6 className="text-dark" style={{lex: 1}}>{grabObj?.capital}</h6>
                                            
                                            <h6 className="text-dark" style={{}}>
                                              <CountdownWithGrowth 
                                                beginTime={new Date(Number(grabObj?.timeCreatedRO) * 1000).getTime()} 
                                                futureTime={new Date(grabObj?.timeToMatured).getTime()} 
                                                finalAmount={Number(grabObj?.profit)} 
                                              />
                                            </h6>

                                          </div>
                                        </div>} 
                                      </div>

                                    </div>
                                  </div>
                                })}


                                
                              </div>
                            </div>


                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <DownModal isOpen={openAmountPanel} onClose={toggleAccountPanel}>
                    
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div className="" style={{maxHeight: 'auto', width: '100%', paddingBottom: 100}}>

                    


                    <form onSubmit={tradeNow}>

                        <div>

                            <div>
                              <div className="single-input-wrap">

                                <span className="new-input-label text-white">Power Amount</span>
                                <input style={{color: '#000'}} placeholder={0.00} value={tradeAmount} onChange = {e => setTradeAmount(e.target.value) } required="" className="form-field form-control" type="text" />

                              </div>
                            </div>

                        </div>

                        <div>
                            <button type="submit" disabled={loading} className="btn">
                            {!loading && 'Mine'} 
                            {loading && 'Please wait...'} 
                            </button>
                        </div>
                        
                    </form>


                </div>
            </div>

          </DownModal>
      
        </SiteWide>
    );

}

export default Minery;