import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";
import TransactionHolder from "../customComponents/TransactionHolder";

import {systemColor} from '../../utility/styles';

    

function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);
    const [dataHasChanged, setDataHasChanged]   = useState(false);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [enterAmountModal, setEnterAmountModal]   = useState(false);
    const [newDepositInfo, setNewDepositInfo]   = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);
    const systemColorManager = systemColor()


    const search = useLocation();
    const depositRequest = search?.state?.deposit;

    const loadAllDeposit = () => {

        setloading(true)
        setLoadingText('Loading payment history...')
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setDataHasChanged(!dataHasChanged)
            }
        }))

    }



    useEffect(() => {
        loadAllDeposit()
    }, [])



    useEffect(() => {

        let pendingDeposit = loadHistoryData?.filter((item, index) => item.status < 2)

        if(pendingDeposit?.length > 0){
            setIsOpen(true);
            setNewDepositInfo(pendingDeposit)
        }

    }, [dataHasChanged])

    const { pathname } = useLocation();




    const status = ['Pending', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide title={"History"}>
           

                <TransactionHolder data={loadHistoryData}>

                
                    {loadHistoryData?.map((item, index) => {

                        let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                        return <div class="container" style={{padding: 0}}>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="payment-card">
                        
                                            <h3 className="text-center" style={{color: (item?.status == 1?'red':'green')}}>
                                                {item?.status <2 && <WebLink ededstyle={{...systemColorManager.txt_1, ...systemColorManager.btn_2, padding: 4, borderRadius: 4}} className="" to={"/deposit"}>{item.txn_id}</WebLink>}
                                                    {item?.status >=2 && <span ededstyle={{...systemColorManager.txt_1, ...systemColorManager.btn_2, padding: 4, borderRadius: 4}} className="" to={"/deposit"}>{item.txn_id}</span>}
                        
                                            </h3>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <small>
                                                        <strong className="text-dark">{money(item?.amountEnforcedToPay, item?.currency)}</strong>
                                                    </small>
                                                </div>
                                                <div class="col-sm-6 text-right">
                                                    <small>
                                                        <strong className="text-dark"><CustomAgo time={item?.timestamp}/></strong>
                                                    </small>
                                                </div>
                                            </div>
                        
                                        </div>
                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    })}


                </TransactionHolder>




                
            

        </SiteWide>
    );
}

export default DepositHistory;