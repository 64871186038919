
import {FcDoughnutChart, FcDataSheet, FcBarChart, FcAreaChart} from "react-icons/fc";
import { SlHome, SlPeople, SlRocket, SlLock } from "react-icons/sl";


export const menuItems = [
    {address: '/', highlightMenu: ['/'], title: 'Home', icon: SlHome},
    {address: '/minery', highlightMenu: ['/minery'], title: 'Mine', icon: SlRocket},
    {address: '/my-team', highlightMenu: ['/my-team'], title: 'Team', icon: SlPeople},
    {address: '/personal-information', highlightMenu: ['/deposit', '/personal-information', '/bank', '/wallet', '/change-password', '/signin', '/signup', '/reset-password'], title: 'Account', icon: SlLock},
]

