import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';
import LoadingOverlay from 'react-loading-overlay';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";

import {systemColor} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0
import './PersonalInformation.css';


import { MdDonutLarge, MdGavel, MdExitToApp,MdAccountBalanceWallet, MdAccountBalance, MdVerifiedUser, MdOutlineVpnLock, MdGroupWork, MdLockClock, MdArrowRightAlt } from "react-icons/md";


function PersonalInformation(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');
    const [uploadingAvatar, setUploadingAvatar]     = useState(false);

    const [password, setPassword]       = useState('');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    
    const systemColorManager = systemColor()

    const avatarImg = ((userData?.image ?? null) != null)?userData?.image:require('../images/avatar3.png')

    const uploadAvatar = async (file) => {

        if(uploadingAvatar){ return;}

        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }


    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }



    React.useEffect(() => {

        loadAllHomePageData();
        

    }, [])


    const logout = () => {

        dispatch(authActions.onLogout())
        history.push('/signin')

    }

    const dashMenu = [
        
       
        {
            icon: MdLockClock,
            name: 'Deposit',
            color: '#392',
            to: () => history.push('/deposit'),
            show: true 
        },
        {
            icon: MdDonutLarge,
            name: 'Password',
            color: '#392',
            to: () => history.push('/change-password'),
            show: true,
        },
        {
            icon: MdGavel,
            name: 'PIN',
            color: '#392',
            to: () => history.push('/change-pin'),
            show: (site_settings.operational_settings.use_pin == 1) 
        },

        // {
        //     icon: MdExitToApp,
        //     name: 'Auth',
        //     color: '#392',
        //     to: () => history.push('/google-auth'),
        //     show: (site_settings.operational_settings.use_google_auth == 1) 
        // },

        {
            icon: MdAccountBalance,
            name: 'Vault',
            color: '#392',
            to: () => history.push('/bank'),
            show: true
        },

        {
            icon: MdAccountBalanceWallet,
            name: 'Crypto',
            color: '#392',
            to: () => history.push('/wallet'),
            show: true
        },


        {
            icon: MdExitToApp,
            name: 'Log Out',
            color: '#392',
            to: logout,
            show: (site_settings.operational_settings.use_google_auth == 1) 
        },
        
        


        
        
    ]



    return (
        <SiteWide id="main" title={`Information`}>

            <div className="transaction-details">
                
                <div className="container" style={{}}>

                   


                    <div class="container bootstrap snippets bootdey">
                        
                        <div class="row">

                            <div class="profile-navol col-md-12">
                                <div class="panel">
                                    <div class="user-heading round" style={{backgroundColor: 'transparent'}}>
                                    
                                    <label className="form-file-label" for="customFile">
                                    <LoadingOverlay
                                        active={uploadingAvatar}
                                        spinner
                                    >
                                        <div className="profillee cursor-pointer" href="#">
                                            <img src={avatarImg} style={{width: 120}} alt={userData?.username} />
                                        </div>
                                    </LoadingOverlay>

                                    <input style={{width: 10, position: 'absolute', top: -10, marginTop: 70, zIndex: -10}} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" className="form-file-input"
                                                    id="customFile"/>
                                    </label>
                                    <h1 className="text-white">{userData?.firstname} {userData?.username}</h1>
                                    <p>{userData?.email}</p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    
                    
                    <div className="flexify" style={{gap: 10, flexWrap: 'wrap'}}>
                        {dashMenu.map(item => {

                            let Icon = item.icon
                            return <button onClick={item.to} class="button-78 flexify" style={{flexDirection: 'column'}} role="button">
                                <div>
                                    <Icon size={30} />
                                </div>
                                <div>{item.name}</div>
                            </button>

                        })}

                        
                    </div>
                    



                </div>



            </div>

        </SiteWide>
    );

}

export default PersonalInformation;
