import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {mainStyle, mainColor, header, footer} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';

import {WebLink, SiteWide, Images} from "../customComponents";
import {systemColor} from '../../utility/styles';

export default function MobileBreaker(props) {

    const systemColorManager = systemColor()

    // return <div className="breakpointed">{props.children}</div>

    return (
        <div className="row g-0 no-gutters" style={{padding: 0, margin: 0, backgroundColor: 'transparent'}}>

            <div className="col-md-3 col-lg-4 d-none d-md-block" style={{padding: 0, margin: 0, backgroundColor: props.secondBackgroundColor ?? '#fff', zIndex: 999999999999, margin:0, padding: 0}}></div>

            <div  style={{margin:0}} className="main-breaker-body col-sm-12 col-md-6 col-lg-4">
                {props.children}
            </div>

            <div className="col-md-3 col-lg-4  d-none d-md-block" style={{padding: 0, margin: 0, backgroundColor: props.thirdBackgroundColor ?? 'transparent', position: 'relative',
            zIndex: 999999999999}}>
            </div>


        </div>
    );

}

