import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onManageDeposit(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);
    if (response.code == 1) {
      yield put({ type: actionTypes.SAVE_DEPOSIT_DATA, data: response.data.deposit });
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onLoadDeposits(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code != 0) {
      yield put({ type: actionTypes.SAVE_DEPOSIT_DATA, data: response.data.deposit});
    }
    return action.callback?.(response);
  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }
}



function* watchOnManageDeposit() {
  yield takeEvery(actionTypes.MANAGE_DEPOSIT, onManageDeposit);
}



function* watchOnLoadDeposits() {
  yield takeEvery(actionTypes.ALL_DEPOSIT, onLoadDeposits);
}



export default function* depositSagas() {
  yield all([watchOnManageDeposit(), watchOnLoadDeposits()]);
}
