import React, { Component, useEffect, useContext } from "react";
import {Router, Route, Switch, HashRouter, useLocation} from "react-router-dom";

import UnverifiedMailRoute from './utility/UnverifiedMailRoute';
import PrivateRoute from './utility/PrivateRoute';
import PublicRoute from './utility/PublicRoute';


import history from "./History";
import Home from "./components/Home";
import About from "./components/About";
import PromotionTool from "./components/PromotionTool";
import News from "./components/News";
import Certification from "./components/Certification";

import Faq from "./components/Faq";
import Term from "./components/Term";
import Privacy from "./components/Privacy";
import Livechat from "./components/Livechats/Livechat";



import FixtureResult from "./components/Football/FixtureDetail";
import FixtureDetail from "./components/Football/FixtureDetail";
import AllFixtures from "./components/Football/AllFixtures";
import ActiveMatches from "./components/Football/ActiveMatches";

import EcommerceList from "./components/Games/Ecommerce/EcommerceList";
import MyEcommerceList from "./components/Games/Ecommerce/MyEcommerceList";


import BlockGames from "./components/Games/BlockGames/BlockGames";
import MyGameList from "./components/Games/BlockGames/MyGameList";
import TradingPlan from "./components/Games/BlockGames/TradingPlan";



import Salary from "./components/Salary";
import Agent from "./components/Agent";
import Map from "./components/Map";

import DepositBonus from "./components/Bonus/Deposit";
import RebateBonus from "./components/Bonus/Rebate";
import ReferralBonus from "./components/Bonus/Referral";




import Wallet from "./components/Wallet";

import ChangePin from "./components/Security/ChangePin";
import GoogleAuth from "./components/Security/GoogleAuth";
import ForgetPassword from './components/Auth/ForgetPassword';
import ChangePassword from "./components/Security/ChangePassword";
import Security from "./components/Security";


import Phone from "./components/Phone";



import AllPages from "./components/AllPages";
import BillPay from "./components/BillPay";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import Carts from "./components/Carts";
import Components from "./components/Components";
import ComponentsAccordion from "./components/ComponentsAccordion";
import ComponentsAlerts from "./components/ComponentsAlerts";
import ComponentsButtons from "./components/ComponentsButtons";
import ComponentsModals from "./components/ComponentsModals";
import ComponentsNotifications from "./components/ComponentsNotifications";
import ComponentsProgress from "./components/ComponentsProgress";
import ComponentsTabs from "./components/ComponentsTabs";

import Contact from "./components/Contact";
import Notification from "./components/Notification";
import Saving from "./components/Saving";
import Signin from "./components/Auth/Signin";
import Signup from "./components/Auth/Signup";


import Transaction from "./components/Transaction";
import TransactionDetails from "./components/TransactionDetails";

import PersonalInformation from "./components/PersonalInformation";

import Minery from "./components/Minery";
// import Bot from "./components/Bot";


import Asset from "./components/Asset";
import Chats from "./components/Chats";
import KYC from "./components/KYC/Manage";
import AllKYC from "./components/KYC/AllKYC";


import Testimony from "./components/Testimony";


import TradeHistory from "./components/Trade-history";


import Team from "./components/Referral/Team";
import Invite from "./components/Referral/Invite";



import DepositHistory from "./components/Deposits/History";
import Deposit from "./components/Deposits/New";

import WithdrawalHistory from "./components/Withdrawals/History";
import Withdraw from "./components/Withdrawals/New";

import TransferHistory from "./components/Transfer-history";


import UserVerification from "./components/Auth/UserVerification";
import { useSelector, useDispatch } from 'react-redux';
import {authActions, tradeActions} from './redux/actions';
import { userSelect, allRemoteSettingsSelect } from './redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import ScrollToTop from './ScrollToTop';
import MobileBreaker from "./components/layouts/MobileBreaker";
import {systemColor} from './utility/styles';


    



function Routes(){

    const dispatch = useDispatch();

    const site_settings         = useSelector(allRemoteSettingsSelect);
    const major_site_settings   = site_settings?.color_settings?.[0]



    const userData = useSelector(userSelect);

    const [loading, setLoading] = React.useState(true);
    const systemColorManager = systemColor()


    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("system/refresh", 10, {}, response => {
            setLoading(false)
        }))
    }, [userData?.email])


    
    
    history.listen(_ => {
        window.scrollTo(0, 0)  
    })

    return (<>
        

            {!loading && <Router history={history}>
                <HashRouter basename="/">
                <ScrollToTop />
                    <Switch>
                        {/* <Route exact path={'/all-pages'} render={(props) => (<AllPages {...props} />)} />
                        <Route exact path={'/bill-pay'} render={(props) => (<BillPay {...props} />)} />
                        <Route exact path={'/blog'} render={(props) => (<Blog {...props} />)} />
                        <Route exact path={'/blog-details'} render={(props) => (<BlogDetails {...props} />)} />
                        <Route exact path={'/carts'} render={(props) => (<Carts {...props} />)} />
                        <Route exact path={'/components'} render={(props) => (<Components {...props} />)} />
                        <Route exact path={'/components-accordion'} render={(props) => (<ComponentsAccordion {...props} />)} />
                        <Route exact path={'/components-alerts'} render={(props) => (<ComponentsAlerts {...props} />)} />
                        <Route exact path={'/components-buttons'} render={(props) => (<ComponentsButtons {...props} />)} />
                        <Route exact path={'/components-modal'} render={(props) => (<ComponentsModals {...props} />)} />
                        <Route exact path={'/components-notifications'} render={(props) => (<ComponentsNotifications {...props} />)} />
                        <Route exact path={'/components-progress'} render={(props) => (<ComponentsProgress {...props} />)} />
                        <Route exact path={'/components-tabs'} render={(props) => (<ComponentsTabs {...props} />)} />
                        <Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />
                        <Route exact path={'/contact'} render={(props) => (<Contact {...props} />)} />
                        <Route exact path={'/saving'} render={(props) => (<Saving {...props} />)} />
                         */}
                        <Route exact path={'/'} component={Home} />
                        <Route exact path={'/about'} component={About} />
                        {/* <Route exact path={'/promotion-tool'} component={PromotionTool} /> */}

                        <Route exact path={'/certification'} component={Certification} />
                        <PrivateRoute exact path={'/news'} component={News} />

                        <Route exact path={'/faq'} component={Faq} />
                        <Route exact path={'/term'} component={Term} />
                        <Route exact path={'/privacy-policy'} component={Privacy} />
                        <Route exact path={'/customer-care'} component={Livechat} />

                        {/* <PrivateRoute exact path={'/kyc'} component={KYC} /> */}
                        {/* <PrivateRoute exact path={'/all-kyc'} component={AllKYC} /> */}


                        <PublicRoute exact path={'/signin'} component={Signin} />
                        <PublicRoute exact path={'/signup'} component={Signup} />
                        <PublicRoute exact path={'/reset-password'} component={ForgetPassword} />

                        <UnverifiedMailRoute exact path={'/verify-user'} component={UserVerification} />

                        <Route exact path={'/transaction'} render={(props) => (<Transaction {...props} />)} />
                        <Route exact path={'/transaction-details'} render={(props) => (<TransactionDetails {...props} />)} />
                        
                        <PrivateRoute exact path={'/personal-information'} component={PersonalInformation} />

                        <PrivateRoute exact path={'/feedback'} component={Testimony} />
                        <PrivateRoute exact path={'/notification'} component={Notification} />


                        {/* <PrivateRoute exact path={'/game'} component={AllFixtures} />
                        <PrivateRoute exact path={'/games/mine'} component={ActiveMatches} />
 
                         <PrivateRoute exact path={'/ecommerce'} component={EcommerceList} />

                         <PrivateRoute exact path={'/ecommerce/mine'} component={MyEcommerceList} />
                         <PrivateRoute exact path={'/block/games/mine'} component={MyGameList} />
                         <PrivateRoute exact path={'/block/games/plan'} component={TradingPlan} /> */}
                         {/* <PrivateRoute exact path={'/games/:block'} component={BlockGames} /> */}


                        
                        <PrivateRoute exact path={'/asset'} component={Asset} />
                        {/* <PrivateRoute exact path={'/chat'} component={Chats} /> */}
                        <PrivateRoute exact path={'/change-password'} component={ChangePassword} />
                        <PrivateRoute exact path={'/security-center'} component={Security} />
                        
                        <PrivateRoute exact path={'/minery'} component={Minery} />
                        <PrivateRoute exact path={'/trade-record'} component={TradeHistory} />
                        <PrivateRoute exact path={'/deposit-records'} component={DepositHistory} />
                        <PrivateRoute exact path={'/deposit'} component={Deposit} />

                        <PrivateRoute exact path={'/withdrawal-records'} component={WithdrawalHistory} />
                        <PrivateRoute exact path={'/withdraw'} component={Withdraw} />
                        

                        <PrivateRoute exact path={'/my-team'} component={Team} />
                        {/* <PrivateRoute exact path={'/invite-friends'} component={Invite} /> */}

                        {/* <PrivateRoute exact path={'/salary'} component={Salary} />
                        <PrivateRoute exact path={'/agent'} component={Agent} />
                        <PrivateRoute exact path={'/address'} component={Map} /> */}
                        
                        <PrivateRoute exact path={'/bank'} component={Wallet} />
                        <PrivateRoute exact path={'/wallet'} component={Wallet} />
                        <PrivateRoute exact path={'/change-pin'} component={ChangePin} />

                        <PrivateRoute exact path={'/phone'} component={Phone} />
                        
                        {/* <PrivateRoute exact path={'/google-auth'} component={GoogleAuth} />
                        <PrivateRoute exact path={'/deposit-bonus'} component={DepositBonus} />
                        <PrivateRoute exact path={'/rebate-bonus'} component={RebateBonus} />
                        <PrivateRoute exact path={'/referral-bonus'} component={ReferralBonus} /> */}
                        

                        {/* <PrivateRoute exact path={'/fixture/:fixture_id'} component={FixtureDetail} />
                        <PrivateRoute exact path={'/fixture/result'} component={FixtureResult} /> */}




                    </Switch>
                </HashRouter>
            </Router>}

            {loading && <MobileBreaker>
                {/* , ...systemColorManager.?preloader_1 */}
                <div id="center-text" className="text-center" style={{backgroundColor: '#473f97'}}>  

                    <img style={{maxHeight: '40px'}} src={major_site_settings?.preloaderImage ?? require('./images/blinking.gif')} />
                    <br />
                    <h1 style={{...systemColorManager.txt_1, marginTop: 10}}>{major_site_settings?.preloaderText ?? ''}</h1>

                </div>
            </MobileBreaker>}

        </>
    )

}

export default Routes;
