import React, { useState, useEffect } from 'react';

export const CountdownWithGrowth = ({ beginTime, futureTime, finalAmount }) => {
  const [currentAmount, setCurrentAmount] = useState(0);

  useEffect(() => {
    const currentTime = Date.now();

    const totalTime = Math.max(futureTime - beginTime, 1); // Avoid division by zero
    const elapsedTime = Math.max(currentTime - beginTime, 0); // Clamp elapsedTime

    if (currentTime >= futureTime) {
      setCurrentAmount(finalAmount);
      return;
    }

    const elapsedPercentage = elapsedTime / totalTime;
    const initialAmount = finalAmount * elapsedPercentage;
console.log('beginTime', beginTime)
    setCurrentAmount(initialAmount);

    // Calculate the increment interval and amount
    const incrementInterval = 100;
    const amountIncrement = finalAmount / (totalTime / incrementInterval);

    const intervalId = setInterval(() => {
      setCurrentAmount((prevAmount) => {
        const newAmount = Math.min(prevAmount + amountIncrement, finalAmount);

        if (Date.now() >= futureTime) {
          clearInterval(intervalId);
          return finalAmount;
        }

        return newAmount;
      });
    }, incrementInterval);

    return () => clearInterval(intervalId);
  }, [beginTime, futureTime, finalAmount]);

  return (
    <span>
      {Number(currentAmount).toFixed(2)}
    </span>
  );
};