import React, {Component, useState} from "react";
import {WebLink, Images} from "../customComponents";
// import { ToastContainer } from 'react-toastify';

import {useHistory} from "react-router-dom";
import MobileBreaker from "./MobileBreaker";
import {systemColor} from '../../utility/styles';

import { RiMenuLine } from "react-icons/ri";
import { FiMoreHorizontal, FiArrowLeft, FiUser, FiLogIn, FiShare2 } from "react-icons/fi";

import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';
import { RWebShare } from "react-web-share";



function Header(props) {
    
        const [uploadingAvatar, setUploadingAvatar]           = useState(false);
        const hiddenFileInput = React.useRef(null);

        const systemColorManager = systemColor()
        const dispatch = useDispatch();

        const history = useHistory();
        const userData = useSelector(userSelect);
        const site_settings = useSelector(allRemoteSettingsSelect);

        const loadHome = () => {

            const whichFun = history?.goBack ?? history?.push
            whichFun?.('/')
            // if(history?.goBack){
            //     history.goBack() ?? history.push('/')
            // }else{
            //     history.push('/')
            // }

            // history.goBack()
        }


        const progClickFileUpload = () => {
            if(!userData?.image){ history.push('/signin'); return;}
            hiddenFileInput.current.click();
        }


        const uploadAvatar = async (file) => {

            // instantUploadPreview(file, 'avatarPlace');
            setUploadingAvatar(true)

            dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                    setUploadingAvatar(false)
                }),
            )
      
        }

    

        // const doLogOutNowNow = () => {

        //     if(!userData?.username){ history.push('/signin'); return;}

        //     dispatch(authActions.onLogout())
            
        // }


        const shareUserReferalLink = () => {

            if(!userData?.username){ history.push('/signin'); return;}
            
        }
        

        const myRefCode = userData?.myrefid ?? ''
        const myRefCode2 = myRefCode != '' ? `/#/signup?ref=${myRefCode}`:''

        const myRefLink = site_settings?.site_settings?.site_url + myRefCode2

        return <div className="" style={{position: 'fixed', width: '100%', zIndex: 999999999, top: 0, left: 0}}>
                    
                    <MobileBreaker>
                        
                        <div className="header-area purple-rain-bg-1">
                            <div className="container">
                                <div className="row" style={{display: 'flex', gap: 20, justifyContent: 'center', alignItems: 'center'}}>
                                    <div className="">
                                        
                                        {!props?.title && <div className="menu-bar cursor-pointer" style={systemColorManager.txt_1, {marginLeft: 20}}>
                                            <FiMoreHorizontal size={20} />
                                        </div>}

                                        {props?.title && <div onClick={loadHome} className="cursor-pointer" style={systemColorManager.txt_1, {marginLeft: 20}}>
                                            <FiArrowLeft size={20} />
                                        </div>}

                                    </div>

                                    <div style={{}} className="text-left">
                                        
                                        {!props?.title && <WebLink to={'/'} className="">
                                            <span>{site_settings?.site_settings?.sitename}</span>
                                        </WebLink>}
                                        
                                        {props?.title && <div onClick={loadHome} className="cursor-pointer">
                                            <span>{props?.title}</span>
                                        </div>}
                                        
                                        {/* <WebLink to={'/'} className="logo">
                                            <Images style={{ height: '50px' }} src={(site_settings?.site_settings?.logo)} alt="logo" />
                                        </WebLink> */}
                                    </div>

                                    <div style={{flex: 1}} className="text-left"></div>

                                    <div className="text-right" style={{display: 'flex', gap: 10, flexDirection: 'row',}}>
                                        
                                        {props?.sitewideProps?.additionalTopIcons}

                                        <WebLink to={"/personal-information"}  className="cursor-pointer" style={{marginRight: 10}}>
                                            <FiUser size={20} />
                                        </WebLink>

                                        <div className="cursor-pointer" style={{flex: 1, marginRight: 20}} onClick={shareUserReferalLink}>
                                            
                                            <RWebShare
                                                data={{
                                                text: "Invite friends to your team",
                                                url: myRefLink,
                                                title: "Share Invitation Link",
                                                }}
                                                onClick={() => console.log("shared successfully!")}
                                            >
                                                <FiShare2 size={20} />

                                            </RWebShare>
                                        </div>

                                        {/* <LoadingOverlay
                                        active={uploadingAvatar}
                                        spinner
                                        >
                                            <Images style={{width: 30, height: 30, borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer im" onClick={progClickFileUpload} width="60px" source={((userData?.image ?? null) != null)?userData?.image:require('../../images/athlete1.png')} alt="avatar" />
                                            <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>

                                        </LoadingOverlay> */}

                                        {/* <Images style={{marginLeft: 5, width: 30, height: 30, borderRadius: '50%'}} title="Click to logout" className="cursor-pointer" onClick={doLogOutNowNow} width="60px" source={require('../../images/log-out.png')} alt="logout" /> */}

                                        
                                    </div>
                                </div>

                            </div>
                        </div>






                    </MobileBreaker>

                </div>


}

export default Header;