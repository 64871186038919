import Cookies from 'js-cookie'
import { store } from "../redux/store";
import {hexToRgba} from '../utility/Helper1';

// main background imagery

const getColors = () => store.getState()?.settings?.settings?.color_settings;

export const systemColor = (settings) => {
	
	let color = getColors()?.[0]

	const darkMode = Cookies.get('darkMode') ?? true
	const isDarkMode = darkMode == 1
	const bgImage 	= color?.bg_main_image;
	const lawn 	= color?.side_menu_image;
	

	const bg_main = isDarkMode?`rgba(${hexToRgba(color?.bg_main_colors_darkmode_1)}, 0.8), rgba(${hexToRgba(color?.bg_main_colors_darkmode_2)}, 0.8), rgba(${hexToRgba(color?.bg_main_colors_darkmode_3)}, 0.8)`:`rgba(${hexToRgba(color?.bg_main_colors_lightmode_1)}, 0.8), rgba(${hexToRgba(color?.bg_main_colors_lightmode_2)}, 0.8), rgba(${hexToRgba(color?.bg_main_colors_lightmode_3)}, 0.8)`
	
	const bg_main_color = isDarkMode?color?.header_color_darkmode_1:color?.header_color_lightmode_1

	const txt_1 = isDarkMode?color?.txt_color_darkmode_1:color?.txt_color_lightmode_1
	const txt_2 = isDarkMode?color?.txt_color_darkmode_2:color?.txt_color_lightmode_2
	const txt_3 = isDarkMode?color?.txt_color_darkmode_3:color?.txt_color_lightmode_3

	const borders_color_1 = isDarkMode?color?.borders_color_darkmode_1:color?.borders_color_lightmode_1
	const borders_color_2 = isDarkMode?color?.borders_color_darkmode_2:color?.borders_color_lightmode_2

	const bg_1 = isDarkMode?`${color?.bg_darkmode_1_i}, ${color?.bg_darkmode_2_i} , ${color?.bg_darkmode_3_i}`:`${color?.bg_lightmode_1_i}, ${color?.bg_lightmode_2_i} , ${color?.bg_lightmode_3_i}`
	const bg_2 = isDarkMode?`${color?.bg_darkmode_1_ii}, ${color?.bg_darkmode_2_ii} , ${color?.bg_darkmode_3_ii}`:`${color?.bg_lightmode_1_ii}, ${color?.bg_lightmode_2_ii} , ${color?.bg_lightmode_3_ii}`
	const bg_3 = isDarkMode?`${color?.bg_darkmode_1_iii}, ${color?.bg_darkmode_2_iii} , ${color?.bg_darkmode_3_iii}`:`${color?.bg_lightmode_1_iii}, ${color?.bg_lightmode_2_iii} , ${color?.bg_lightmode_3_iii}`
	
	// preloader
	const preloader_1 = isDarkMode?`${color?.preloader_darkmode_bg_color_1_i}, ${color?.preloader_darkmode_bg_color_2_i}`:`${color?.preloader_lightmode_bg_color_1_i}, ${color?.preloader_lightmode_bg_color_2_i}`
	

	// header and footer
	const header = isDarkMode?`${color?.header_color_darkmode_1}, ${color?.header_color_darkmode_2}, ${color?.header_color_darkmode_3}`:`${color?.header_color_lightmode_1}, ${color?.header_color_lightmode_2}, ${color?.header_color_lightmode_3}`
	const footer = isDarkMode?`${color?.footer_color_darkmode_1}, ${color?.footer_color_darkmode_2}, ${color?.footer_color_darkmode_3}`:`${color?.footer_color_lightmode_1}, ${color?.footer_color_lightmode_2}, ${color?.footer_color_lightmode_3}`
	

	//side menu
	const side_menu = isDarkMode?`rgba(${hexToRgba(color?.side_menu_bg_color_darkmode_1)}, 1), rgba(${hexToRgba(color?.side_menu_bg_color_darkmode_1)}, 0.7)`:`rgba(${hexToRgba(color?.side_menu_bg_color_lightmode_1)}, 0.8), rgba(${hexToRgba(color?.side_menu_bg_color_lightmode_2)}, 0.8)`
	const menu_icon_color = isDarkMode?color?.menu_icon_color_darkmode:color?.menu_icon_color_lightmode
	const submenu_bg = isDarkMode?`${color?.submenu_bg_darkmode_1}, ${color?.submenu_bg_darkmode_2}, ${color?.submenu_bg_darkmode_3}`:`${color?.submenu_bg_lightmode_1}, ${color?.submenu_bg_lightmode_2}, ${color?.submenu_bg_lightmode_3}`
	const submenu_arrow = isDarkMode?color?.submenu_arrow_color_darkmode:color?.submenu_arrow_color_lightmode

	// overlay bg color

	const bg_overlay = isDarkMode?`rgba(${hexToRgba(color?.bg_overlay_color_darkmode)}, 0.4)`:`rgba(${hexToRgba(color?.bg_overlay_color_lightmode)}, 0.4)` //for pages that need additional overlay like about us
	

	// button bg color
	const btn_singly_1 = isDarkMode?`${color?.btn_darkmode_1_i}`:`${color?.btn_lightmode_1_i}`
	const btn_1 = isDarkMode?`${color?.btn_darkmode_1_i}, ${color?.btn_darkmode_2_i}`:`${color?.btn_lightmode_1_i}, ${color?.btn_lightmode_2_i}`
	const btn_2 = isDarkMode?`${color?.btn_darkmode_1_ii}, ${color?.btn_darkmode_2_ii}`:`${color?.btn_lightmode_1_ii}, ${color?.btn_lightmode_2_ii}`
	const btn_3 = isDarkMode?`${color?.btn_darkmode_1_iii}, ${color?.btn_darkmode_2_iii}`:`${color?.btn_lightmode_1_iii}, ${color?.btn_lightmode_2_iii}`
	const btn_4 = isDarkMode?`${color?.btn_darkmode_1_iv}, ${color?.btn_darkmode_2_iv}`:`${color?.btn_lightmode_1_iv}, ${color?.btn_lightmode_2_iv}`
	const btn_5 = isDarkMode?`${color?.btn_darkmode_1_v}, ${color?.btn_darkmode_2_v}`:`${color?.btn_lightmode_1_v}, ${color?.btn_lightmode_2_v}`
	const btn_6 = isDarkMode?`${color?.btn_darkmode_1_vi}, ${color?.btn_darkmode_2_vi}`:`${color?.btn_lightmode_1_vi}, ${color?.btn_lightmode_2_vi}`


	// home ticker
	const tickerStrip_bg = isDarkMode?hexToRgba(color?.tickerStrip_bg_darkmode):hexToRgba(color?.tickerStrip_bg_lightmode) //for pages that need additional overlay like about us
	const tickerStrip_text = isDarkMode?color?.tickerStrip_text_darkmode:color?.tickerStrip_text_lightmode //for pages that need additional overlay like about us
	const tickerStrip_main_text = isDarkMode?color?.ticker_mainbg_text_darkmode:color?.ticker_mainbg_text_lightmode //for pages that need additional overlay like about us
	const tickerMainBgImage 	= isDarkMode?color?.ticker_mainbg_image_darkmode:color?.ticker_mainbg_image_lightmode;


	return {
			// background Colors

			bg_1: 		{background: `linear-gradient(to bottom, ${bg_1})`}, // deep background color
			bg_2: 		{background: `linear-gradient(to bottom, ${bg_2})`}, //light backround color useable for boxed area
			bg_3: 		{background: `linear-gradient(to bottom, ${bg_3}`}, //light backround color useable for strips or boxed area enclosed in bg_2
			
			preloader_1: 		{background: `linear-gradient(to bottom, ${preloader_1}`}, //light backround color useable for strips or boxed area enclosed in bg_2

			
			submenu_bg: 			{background: `linear-gradient(to bottom, ${submenu_bg})`}, //just blue for sub-menu background
			submenu_arrow,

			bg_main_color,
			bg_main: 	{	background: 		`url(${bgImage}) no-repeat`,},

		  	bg_overlay: { backgroundColor: bg_overlay, },

		  	no_bg: 		{background: 'transparent', },


		  	side_menu_bg: 			{ background: 		`linear-gradient(to bottom, ${side_menu}), url(${lawn})`,},
			menu_icon_color: 	menu_icon_color,

		  	header: 	{background: `linear-gradient(to right, ${header})`},
		  	footer: 	{background: `linear-gradient(to right, ${footer})`},
			// button color

			btn_singly_1: 				btn_singly_1,
			btn_1: {background: 		`linear-gradient(to right, ${btn_1})`}, //normal light btn bg color, no bg radius
			btn_2: {background: 		`#fff`}, //bg radius btn
			btn_3: {background: 		`linear-gradient(to right, ${btn_3}`}, //bg radius btn
			btn_4: {background: 		`linear-gradient(to right, ${btn_4}`}, //bg radius btn
			btn_5: {background: 		`linear-gradient(to right, ${btn_5}`}, //bg radius btn
			btn_6: {background: 		`linear-gradient(to right, ${btn_6}`}, //bg radius btn

			// txt color

			txt_singly_1: 	txt_1, 		//normal text color
			txt_1: {color: 	txt_1}, 		//normal text color

			txt_singly_2: 	txt_2, 		//normal text color\
			txt_2: {color: txt_2}, 	//text color on deep background

			txt_singly_3: txt_3,
			txt_3: {color: txt_3}, 	//text color on deep background

			borders_singly_color: borders_color_1,
			borders_color: { border: `${borders_color_1} solid 0px`, },
			borders_color_2: { border: `${borders_color_2} solid 0px`, },

			// home ticker 
			tickerStrip_bg: 	{backgroundColor: `rgba(${tickerStrip_bg}, 0.9)`},
			tickerStrip_text: 	{color: tickerStrip_text},
			tickerStrip_main_text: 	{color: tickerStrip_main_text},
			ticker_main_bg_image: 	{background: `url(${tickerMainBgImage}) no-repeat`}



		}

}

























































// everything downwards to be removed


export const mainColor 	= '#062a72'
export const header 	= '#473f97'
export const footer 	= '#272645'

// '#262a35'

export const otherColors = {
	// card: '#6a53ec',
	card: 'linear-gradient(to right, #af7cf4 , #d2a4fd)',
	eachBox: '#353454',
	body: 'linear-gradient(to right, #af7cf4 , #d2a4fd)',
	cotpBlue: 'transparent',
	text: '#000',
	btn1: 'radial-gradient(ellipse farthest-corner at right bottom,#7b346e 0%,#7b346e 8%,#7b346e 30%,#7b346e 40%,transparent 80%), radial-gradient(ellipse farthest-corner at left top,#7b346e 0%,#7b346e 8%,#7b346e 25%,#7b346e 62.5%,#7b346e 100%)',
	colorBg1: 'linear-gradient(to right, #00c89c , #61eea6)',
	// iconColor: '#3f51b5',
	iconColor: '#000',
}



export const mainStyle = {
	mainBackground: {
		backgroundColor: '#041f4b', 
		padding: 20, 
		borderRadius: 10, 
		color: mainColor
	},
	color_1:{
		color: mainColor
	}
}







