import React, {Component, useEffect, useState} from "react";
import Colors from '../../utility/Colors';
import {systemColor} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';
import Navbar from "./Navbar";

import MobileBreaker from "./MobileBreaker";

import {WebLink, SiteWide, Images} from "../customComponents";
import {useLocation} from "react-router-dom";
import "./bottom-tab.css";
import { SlHome } from "react-icons/sl";
import {useHistory} from "react-router-dom";


function Footer() {

		const location = useLocation();
		const currentPath = location.pathname;

        const { pathname } = useLocation();
        const history = useHistory();

        const systemColorManager = systemColor()

        const [activeMenu, setActiveMenu] = useState('')

        const isPage = (item) => item.address == pathname || 
            (item.address == '/mine' && (pathname == '/signup' || pathname == '/signin' || pathname == '/reset-password'))

		const clickedObj = (item, index) => {
			history.push(item.address)
		}

		useEffect(() => {
			setActiveMenu(currentPath)
		}, [currentPath])


		return (
            <>

                <div className="footer-area">
                    

                    <div className="conainer">
                        <div className="footer-bottom" style={{}}>

                            <MobileBreaker>
                                
							

								<div class="menu">
									
									{menuItems.map((item, index) => {
										
										const Icon = item.icon
										let shouldHighlight = item.highlightMenu.includes(currentPath)

										return <div onClick={() => clickedObj(item, index)} class={`cursor-pointer link ${shouldHighlight?'maybeactive':''}`}>
												<span class="link-icon">
													<Icon />
												</span>
												<span class="link-title">{item.title}</span>
											</div>
									})}
								
								</div>


                            </MobileBreaker>

                        </div>
                    </div>


                </div>





            </>
        );

}

export default Footer;