import React, { useState } from 'react';

const Taabaa = (p: Props) => {
    
  return (
    <>
      <div style={{ position: 'relative', backgroundColor: '#6c65ac', marginTop: 25, justifyContent: 'center', paddingLeft: 5, paddingRight: 5, alignItems: 'center', height: 60, borderRadius: 50, display: 'flex', gap: 5, flexDirection: 'row' }}>
              
              {p.tabbedDashArr.map((item, position) => (
                <div key={position} className="cursor-pointer" onClick={() => p.callMethod(position)} style={{ justifyContent: 'center', color: p.index === position ? '#000' : '#fff', alignItems: 'center', display: 'flex', height: 50, flex: 1, zIndex: 999, borderRadius: 50}}>
                  {item.title}
                </div>
              ))}

              <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', height: '50px', width: '50%', backgroundColor: '#fff', zIndex: 1, borderRadius: '990px', transition: '0.25s ease-out', transform: `translateX(${p.index == 0?-47.5:47.5}%)`}} />

            </div>
    </>
  );
};

export default Taabaa;
