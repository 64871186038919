import React from "react";
import Sheet from 'react-modal-sheet';
import MobileBreaker from "./MobileBreaker";


export default function DownModal(props){
    

	const {isOpen, onClose, containerStyle} = props;


	return <Sheet detent={"content-height"} style={{zIndex: 98}} mountPoint={document.getElementsByClassName("mother-of-all")[0]} isOpen={isOpen} onClose={onClose}>
            <Sheet.Container style={{...(containerStyle ?? {backgroundColor: '#473f97'}), zIndex: 99, background: '#473f97'}}>

                <Sheet.Header />
                <Sheet.Content>
                	<MobileBreaker secondBackgroundColor="transparent">
                        <div style={{paddingLeft: 20, paddingRight: 20}}>
                    	    {props.children}
                        </div>
                    </MobileBreaker>
                </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop onClick={onClose}>

				<div style={{ background: 'rgba(0, 0, 0, 0.8)', height: '100vh' }}>
				</div>

  			</Sheet.Backdrop>

          </Sheet>

}

