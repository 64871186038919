import {Link, useHistory} from 'react-router-dom';
import { store } from "../redux/store";
import {authActions} from '../redux/actions';


const getToken = () => store.getState().auth?.user?.token;

export const homepage = process.env.REACT_APP_HOMEPAGE;
export const contact_us = process.env.REACT_APP_CONTACT_PAGE;


export default class APIRequests{



  fetchUrl = () => {

    let address;

    if (process.env.NODE_ENV === 'production') {

      const currentUrl = window.location.href;
      const urlWithoutSubdomain = currentUrl.replace(/^(?:https?:\/\/)?(?:[^@\/]+@)?/, '');
      const urlWithoutWww = urlWithoutSubdomain.replace(/^www./, '');
      const newlastUrl = urlWithoutWww.split("/")[0]

      let detectSubDomain =  newlastUrl.split(".")
      let newAdminBase = detectSubDomain.length > 2?`${detectSubDomain[detectSubDomain.length-2]}.${detectSubDomain[detectSubDomain.length-1]}`:newlastUrl

      // address = `https://admin.${newAdminBase}/web/v1/` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin
      address = `https://freshlite.primecrest.biz/web/v1/` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin
      
    }else{

      // return "http://localhost/blockGame/public/web/v1/";
      address = "http://localhost/yorubagirl2024/public/web/v1/";

    }

    return address

  }









  baseUrl = this.fetchUrl()

  
  
  runGet = async (pointEnd) => {

    const accessToken = getToken();

    let data      =   await fetch(this.baseUrl + pointEnd, {
                                headers: {
                                  'Authorization': accessToken,
                                }
                            });

    let response  =   await data.json();
    this.requestActionizer(response);
    return response;

  }
 
  runPost = async (pointEnd, postObject, contentType = 0) => {

    const accessToken = getToken();

    try{

      let ct = (contentType==0)?'application/json':contentType;
      
      const formData = new FormData();
      
      for ( let key in postObject) {
        formData.append(key, postObject[key]);
      }

      let data = await fetch(this.baseUrl + pointEnd, {
          method: 'POST',
          headers: {
              
            'Authorization' : accessToken,

          },
          body: formData,
          
      });



      let response = await data.json();
      this.requestActionizer(response);
      return response;
    
    }catch(err){
      // console.error('protest', err);
      return {code: 0, msg: 'Network request failed. Please check internet connection.'};
    }

  }


  requestActionizer = (response) => {

      if(response.code === -1){
        store.dispatch(authActions.onLogout())
      }

  }


}