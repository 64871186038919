import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {systemColor} from '../utility/styles';

import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import MobileBreaker from "./layouts/MobileBreaker";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, footballSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {footballActions, authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import Helper1, {byNextDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMenuLine, RiCheckboxLine } from "react-icons/ri";
import { AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FiPenTool } from "react-icons/fi";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import Cookies from 'js-cookie'
import Taabaa from './Taabaa'
import './HomeStyle.css'

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";
// import Ticker from 'react-ticker'
import Marquee from 'react-css-marquee'

import EachFixture from "./Football/EachFixture"

import BetModalPlace from './Football/BetModalPlace';
import parse from 'html-react-parser';


import { AiFillAlert, AiFillWechat, AiFillApi, AiFillAppstore, AiFillBook, AiFillBuild, AiFillBulb } from "react-icons/ai";

// import { HiOutlineChartPie, HiOutlineCalculator, HiOutlineCalendarDays, HiOutlineCalendar, HiOutlineCamera, HiOutlineChartBarSquare, HiOutlineChartBar } from "react-icons/hi2";

import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'

import NewsTicker from "./customComponents/Ticker/NewsTicker";
import { PuffLoader } from 'react-spinners';
import SocialMediaIcons from './SocialMediaIcons';
import {useReferralHook} from "./Referral/useReferralHook";
import Minery from "./Minery";


const nextMatch = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}




function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const darkVal = (Cookies.get('darkMode') ?? 1)
    const tradesData = useSelector(allTradeSelect);

    const [isDarkMode, setisDarkMode] = useState(darkVal) //dark mode by default

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const footballData      = useSelector(footballSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [recentBets, setRecentBets]   = useState([]);
    const [recentBetsLoading, setRecentBetsLoading]   = useState(true);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

    const systemColorManager = systemColor()
    const [betModal, setBetModal]          = React.useState(false);
    const [selectedOddObj, setSelectedOddObj]          = React.useState({});
    const refDataRaw = useReferralHook()





    // new state for new project
    const [index, setIndex] = useState(0)

    const dispatch = useDispatch();

    const ref = useRef();

    const loadUserDataAfresh = () => {

    }

    useEffect(() => {
        loadUserDataAfresh()
    }, [])

    // useEffect(() => {
    //     setHomeFixture(byNextDate(footballData?.fixtures));
    // }, [footballData?.fixtures])


    // useEffect(() => {
    //     loadRecentBets()
    // }, [])

    // const loadRecentBets = () => {

    //     if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

    //     setRecentBetsLoading(true)
    //     dispatch(footballActions.onGetRecents("football/home-recent", response => {

    //         setRecentBetsLoading(false)

    //     }))

    // }


    const changeToDarkMode = (booleanVal) => {
        Cookies.set('darkMode', booleanVal, { secure: true, sameSite: 'Lax', expires: 7})
        setisDarkMode(booleanVal)
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])



    
    



    // main slider
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };




    const palettes = [
    {
      from: "#f40076",
      to: "#df98fa"
    },
    {
      from: "#f06966",
      to: "#fad6a6"
    },
    {
      from: "#ff0076",
      to: "#590fb7"
    },
    {
      from: "#9055ff",
      to: "#13e2da"
    },
    {
      from: "#0b63f6",
      to: "#003cc5"
    },
    {
      from: "#d6ff7f",
      to: "#00b3cc"
    },
    {
      from: "#e233ff",
      to: "#ff6b00"
    },
    {
      from: "#df98fa",
      to: "#9055ff"
    },
    {
      from: "#ed7b84",
      to: "#9055ff"
    },
    {
      from: "#402565",
      to: "#30be96"
    },
    {
      from: "#402662",
      to: "#3900a6"
    },
    {
      from: "#f14658",
      to: "#dc2537"
    },
    {
      from: "#f40076",
      to: "#342711"
    },
    {
      from: "#000066",
      to: "#6699ff"
    },
    {
      from: "#cb5eee",
      to: "#4be1ec"
    },
    {
      from: "#fa7cbb",
      to: "#f14658"
    },
    {
      from: "#737dfe",
      to: "#ffcac9"
    },
    {
      from: "#2f80ed",
      to: "#b2ffda"
    }
  ];


    
        
    const tabbedDashArr = [{abbrRo: 'USDTTRC20', title: 'TETHER', longTitle: 'USD Tether', curId: 170 }, {abbrRo: 'NGN', title: 'NAIRA', longTitle: 'Nigerian Naira', curId: 175 }]
    const stat2 = [{ bg: '#4cb5ff', title: 'Friends', amountKey: 'friends', cssClass: 'h6' }, { bg: '#4cd97b', title: 'Capacitor', amountKey: 'targetEngine', cssClass: 'h6' }, { bg: '#9059ff', title: 'Status', amountKey: 'miningStatus', cssClass: 'h6' }]
    const stat1 = [{ bg: '#ffb259', title: 'Balance', amountKey: 'balance' }, { bg: '#ff5959', title: 'Bonus', amountKey: 'refCommission' }]
    const shortMenu = [{title: 'FAQ', to: 'faq', icon: FiPenTool, bgColor: '#4484fd'}, {title: 'T&C', to: 'term', icon: FiPenTool, bgColor: '#ff7854'}, {title: 'Team', to: 'my-team', icon: FiPenTool, bgColor: '#ffb64c'}, {title: 'About', to: 'about', icon: FiPenTool, bgColor: '#ffb64c'}]
    const allDownlines = Object.keys(refDataRaw?.referrals ?? {})
    let sumAllReferrals = 0;

    allDownlines?.map(each => {
      sumAllReferrals += refDataRaw?.referrals?.[each]?.length ?? 0
    })

    const statDiv = (item, objecOfValues) => {

      return  <div className="flexify" style={{flex: 1, backgroundColor: item.bg, flexDirection: 'column', justifyContent: "space-between", alignItems: 'flex-start', padding: 10, paddingLeft: 20, borderRadius: 10, height: 130}}>
        <div>
          {item?.title}
        </div>

        <h4 className={`text-white ${item.cssClass}`}>
          {objecOfValues?.[item.amountKey] ?? 0}
        </h4>

      </div>

    }

    const activeAndPendingHistories = tradesData?.games?.filter(eachHis => eachHis.isProcessed == 0)

    const loadAllTrades = () => {

      if(!userData?.username){ return; }

      dispatch(
        tradeActions.onGetUserTrades("trade/all", response => {
            
            
        }),
      )

    }

    useEffect(() => {
      loadAllTrades();
    }, []); // Empty dependency array ensures this runs only once when the component mounts


    return (

        <SiteWide overlayText={loadingText} overlayBg={{paddingLeft: 0, paddingRight: 0}} loading={loading}>                

          <div className="purple-rain-bg-1 grad-purple generic-padding" 
            style={{marginTop: 20, paddingBottom: 60}}>

            <h2 className="text-white">Hi <span>{userData?.username ?? 'Guest'},</span></h2>

            <Taabaa index={index} tabbedDashArr={tabbedDashArr} callMethod={(position) => setIndex(position)} />
            

            {tabbedDashArr?.map((eachCurr, focusThis) => {

              return <>
                {index == focusThis && <div>
                  
                  <div className="flexify" style={{marginTop: 20, gap: 10 }}>

                    {stat1?.map(item => {

                      const bal = finance?.filter(it => it.abbrRo == eachCurr.abbrRo)

                      return statDiv(item, bal?.[0])
                    
                    })}
                  
                  </div>

                  <div className="flexify" style={{marginTop: 10, gap: 10 }}>
                    {stat2?.map(item => {

                      const feedthis = {...item, miningStatus: activeAndPendingHistories?.length > 0?'Mining':'InActive', targetEngine: userData?.[`targetEngine${eachCurr?.curId}`] ?? 0, friends: sumAllReferrals}
                      return statDiv(item, feedthis)

                    })}

                  </div>
                
                </div>}

              </>

            })}


          </div>



























          
          <div className="purple-rain-bg-1 generic-padding" style={{marginTop: 20, height: '100vh', backgroundColor: '#fff', marginTop: -30, marginBottom: -30, borderTopLeftRadius: 30, borderTopRightRadius: 30}}>


                <div className="flexify" style={{justifyContent: "space-around", marginTop: 20}}>

                  {shortMenu?.map(item => {
                    let Icon = item.icon
                    return <WebLink to={item?.to} className="flexify" style={{flexDirection: 'column'}}>
                        <div className="flexify cursor-pointer" style={{backgroundColor: item.bgColor, borderRadius: 5, height: 40, width: 40}}> 
                          <Icon size={17} />
                        </div>
                        <div sclassName="h6 text-dark" style={{color: '#000', marginTop: 5}}>{item.title}</div>
                      </WebLink>
                  })}
                
                </div>


                                
                <div className="row no-gutter" style={{marginTop: 20}}>
                  <div className="col-12">
                      <Slider className="main-home-slider" arrows={false} {...settings}>
                          {site_settings?.slider_settings?.map((item, index) => {
                              return <div style={{}} className="">
                                  <a target="_blank" href={item?.linkWhenClicked}>
                                      <Images style={{}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                  </a>
                              </div>}
                          )}
                      </Slider>
                  </div>
                </div>










<h1 className="text-center">Friends Commission</h1>

<div class="podium-container">
  <div class="podium">
    <div class="podium__front podium__left">
      L2
      <div class="">3%</div>
      <div class="podium__image"><img src="https://source.unsplash.com/random/100x100" alt=""/></div>
    </div>
    <div class="podium__front podium__center">
      L1
      <div class="">5%</div>
      <div class="podium__image"><img src="https://source.unsplash.com/random/100x100" alt=""/></div>
    </div>
    <div class="podium__front podium__right">
      L3
      <div class="">2%</div>
      <div class="podium__image"><img src="https://source.unsplash.com/random/100x100" alt=""/></div>
    </div>
  </div>
</div>




                <div style={{marginTop: 28, marginBottom: 28}}>

                    <div className="" style={{borderRadius: 10, bzackgroundImage: `linear-gradient(45deg, ${palettes[10].to}, ${palettes[10].from})`}}>


                        <div lassName="purple-rain-bg-1" style={{}}>
                            <p className="text-whi " style={{color: '#000'}}>
                                {parse(site_settings?.content_settings?.[0]?.con3 ?? '')}
                            </p>

                        </div>


                        


                    </div>



                </div>
                      

                <SocialMediaIcons/>

                {(site_settings?.site_settings?.showAccreditation ?? 0) == 1 && <div className="flexify" style={{marginTop: 30, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                    <div className="text-center" style={{flex: 1}}>Copyright &copy; {(new Date).getFullYear()} - All right reserved</div>
                </div>}

           </div>
            
           
           

        </SiteWide>
    );
}

export default Home;
