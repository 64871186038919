import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money} from '../../utility/Helper1';
import NoData from "../customComponents/NoData";

import {withdrawalActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allWithdrawalSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import CustomAgo from "../customComponents/CustomAgo";
import {systemColor} from '../../utility/styles';
import TransactionHolder from "../customComponents/TransactionHolder";


function DepositHistory() {


    const search = useLocation();

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [amount, setAmount]   = useState();
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [walletAddress, setWalletAddress]   = useState('');


    const [enterAmountModal, setEnterAmountModal]   = useState(search?.state?.withdraw ?? false);
    const [newDepositInfo, setNewDepositInfo]   = useState();
    const [changeWhichWallet, setChangeWhichWallet]   = useState(100);


    TimeAgo.addDefaultLocale(en)
    const timeAgo = new TimeAgo('en-US')



    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allWithdrawalSelect);
    const systemColorManager = systemColor()



    const loadAllWithdrawal = () => {

        setloading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setloading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])


    



    const whichWallet = ['Balance', 'Commission']

    const status = ['Processing', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide id="main" title={"Withdrawal Records"}>

            <TransactionHolder data={loadHistoryData}>

                {loadHistoryData?.map((item, index) => { 

                    let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                    return <div class="container" style={{padding: 0}}>
                        <div class="wrapper wrapper-content animated fadeInRight">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="payment-card">

                                        <h3 className="text-center" style={{color: (item?.status == 1?'red':'green')}}>
                                            {item.txn_id}
                                        </h3>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <small>
                                                    <strong className="text-dark">{money(item?.readOnlyTotalWithdrawn, item?.currency)}</strong>
                                                </small>
                                            </div>
                                            <div class="col-sm-6 text-right">
                                                <small>
                                                    <strong className="text-dark"><CustomAgo time={item?.timestamp}/></strong>
                                                </small>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>



                })}

            </TransactionHolder>

        </SiteWide>
    );
}

export default DepositHistory;

