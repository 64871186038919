import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money, capitalizeFirstLetter} from '../../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import CustomAgo from "../customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "../customComponents";

import {RiUser4Line } from "react-icons/ri";
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import {systemColor} from '../../utility/styles';
import './team.css';
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";
import {useReferralHook} from "./useReferralHook";
import { FiShare2 } from "react-icons/fi";





function Referrals() {    

    const systemColorManager = systemColor()

    const [loadingText, setLoadingText]   = useState('Loading team...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [activeClass, setActiveClass]    = useState(1)

    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const refDataRaw = useReferralHook()
    const refData = refDataRaw?.referrals

    const dispatch = useDispatch();
    const allDownlines = refData && Object?.values(refData)?.flat()
console.log('allDownlines', allDownlines)

    // const showReferral = (site_settings?.operational_settings?.useReferralBonus ?? 0) == 1
    // const showRebate = (site_settings?.operational_settings?.useRebateBonus ?? 0) == 1

    const userData = useSelector(userSelect);


    return (<SiteWide loading={loading} overlayText={loadingText} title="Friends">
        
                

<section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center mb-5">
                        <h2 class="heading-section text-white">All Friends </h2>
                    </div>
                </div>
                {/* <p>Click <FiShare2 size={20} /> on the top right corner to invite more friends and earn commission.</p> */}

                <div class="row">
                    <div class="col-md-12">

                        <div class="table-wrap">
                            <table class="table table-bordered text-center">

                                <tbody>

                                    {allDownlines?.map((eachFriend, indexio) => {
                                        
                                       

                                        const showDownline = (eachFriend, currentEnum) => {
                                            const avatarImg = eachFriend?.image

                                            return <td style={{}} className="text-center">
                                                <div style={{display: 'block', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                    <img src={avatarImg?avatarImg: require('../../images/avatar3.png')} className="img rounded-circle mb-2" style={{width: 60, height: 60, display: 'block'}} />
                                                    <a href="#">
                                                        <strong>{capitalizeFirstLetter(eachFriend?.username)}</strong> <br />
                                                        {niceDate(eachFriend.date)}
                                                    </a>
                                                </div>
                                            </td>
                                        }

                                        if (indexio % 2 === 0) {

                                            return (<tr key={indexio}>
                                                {showDownline(eachFriend, indexio)}
                                                {allDownlines[indexio + 1] && showDownline(allDownlines[indexio + 1], indexio+1)}
                                            </tr>);
                                        }

                                        return null;

                                    })}

                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </section>





     
                    

        </SiteWide>
    );

}

export default Referrals;