import React, {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';

import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

import { allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import Notify from './Notify';
import Navbar from "../layouts/Navbar";
import MobileBreaker from "../layouts/MobileBreaker";
import BounceLoader from 'react-spinners/BounceLoader'
import FadeLoader from 'react-spinners/FadeLoader'
import BarLoader from 'react-spinners/BarLoader'



import { Helmet, HelmetProvider } from 'react-helmet-async';

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {systemColor} from '../../utility/styles';
import {Router, Route, Switch, HashRouter, useLocation} from "react-router-dom";
import {WebLink} from "./index";
import {useHistory} from "react-router-dom";
import ChatDelegator from '../Livechats/ChatDelegator'

import Lottie from "lottie-react";
import animation2 from "../Minery/bonus.json";
import confetti from 'canvas-confetti';
import Helper1, {notify} from '../../utility/Helper1';

    


function SiteWide(props) {

    const {title, description, children, removeFooter, ...rest} = props
    const site_settings = useSelector(allRemoteSettingsSelect);
    const dispatch = useDispatch();

    const [masterIsLoading, setMasterIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const finance = useSelector(homeScreenStatSelect);
    const oneWithDepositBonus = finance?.filter(eachBal => eachBal.depositBonus > 0)

    const titleToUse = title ?? site_settings?.site_settings?.title ?? 'welcome';
    const descToUse = description ?? site_settings?.site_settings?.description ?? 'You are here';
    const siteName = site_settings?.site_settings?.sitename ?? 'dotcom';
    const url = site_settings?.site_settings?.site_url ?? 'dotcom';
    const logo = site_settings?.site_settings?.logo ?? '';
    const favicon = site_settings?.site_settings?.favicon ?? '';


    const systemColorManager = systemColor()

    const { pathname } = useLocation();
      const scriptEl = React.useRef(null);

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const loadHome = () => {
        history.goBack()
    }

    // const bgOverlay = props.overlayBg ?? systemColorManager.bg_overlay
    const bgOverlay = props.overlayBg
    
    useEffect(() => {
        setMasterIsLoading(site_settings?.masterIsLoading ?? true)
    }, [site_settings?.masterIsLoading])

    const squareLoading = (props.loading || masterIsLoading)
    
    const claimDepositBonus = () => {

        setLoading(true)

        dispatch(authActions.onSignUp("withdraw/bonus", {currency: oneWithDepositBonus?.[0]?.type}, response => {

            setLoading(false)
            
            if(response.code == 1){
                
                confetti({
                    particleCount: 400,
                    spread: 120,
                    origin: { y: 0.6 },
                    gravity: 0.5
                  });

            }else{
                notify(response.msg, response.code)
            }

        }),
        );

    }


    return (
        <HelmetProvider>
            
            <Helmet>

                <meta name="theme-color" content={'#473f97'} />

                <title>{titleToUse}</title>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={descToUse} />

                <meta property="og:title" content={titleToUse} />
                <meta property="twitter:title" content={titleToUse} />
      
                <meta property="og:site_name" content={siteName} />
                <meta property="twitter:site" content={siteName} />

                <meta property="og:description" content={descToUse} />
                <meta property="twitter:description" content={descToUse} />
      
                <meta property="og:type" content="website" />

                <meta property="og:url" content={url} />

                <meta property="og:image" content={logo} />
                <meta property="twitter:image" content={logo} />

                <meta name="twitter:card" content="summary_large_image" />

            </Helmet>

            <MobileBreaker thirdBackgroundColor="#fff">
                
                <Navbar />
            
                <div className="mother-of-all" {...rest} style={props.background?{...props.background}:{...systemColorManager.bg_main}} >

                    <div className="mainChildren purple-rain-bg-1" style={{paddingLeft: 15, paddingRight: 15, ...bgOverlay, }}>
                        
                        <section className="main-section" style={{...props.sectionstyle, position: 'relative', top: 0, paddingTop: 60}}>
        
                            
                            {!props.hideHeader && <Header back={props.back} sitewideProps={props} title={props.title} rightTopMenu={props.rightTopMenu} home={props.home} searchPopup={props.searchPopup} />}
                
                            <Notify/>

                            {props.title && 1>2 && <div className=""  style={{}}>
                                <div className="container">

                                    <div onClick={loadHome} className="h6" style={{cursor: 'pointer', display: 'flex', ...systemColorManager.txt_3}}>

                                        <WebLink className="menu-back-page home-clicked ext-dark" style={{...(props.textColor ?? {color: '#000'})}}>
                                            <i className="fa fa-long-arrow-left" style={{}} />
                                        </WebLink>
                                    
                                        <div className="page-name ext-dark" style={{marginLeft: 12, ...(props.textColor ?? {color: '#000'})}}>{props.title}</div>

                                    </div>

                                </div>
                            </div>}






                            {children}
                
                            <br />

                            {!removeFooter && <Footer />}

                            {squareLoading && <LoadingOverlay
                                active={squareLoading}
                                styles={{


                                        overlay: (base) => ({
                                          ...base,
                                          background: 'transparent',
                                          borderRadius: 5,
                                          color: '#000',
                                        }),

                                       
                                        wrapper: {
                                            width: '130px',
                                            height: 'auto',
                                            padding: '5px',
                                             background: 'rgba(255, 255, 255, 0.9)',
                                            position: squareLoading?'fixed':'none',
                                            zIndex: '999999999999999999999999',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#000'
                                        }
                                    }}
                            >
                                <div className="text-center" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10}}>

                                    
                                    <h6 className="text-dark" style={{...systemColorManager.txt_1, marginTop: 10}}>
                                        {(props.overlayText ?? '' !='') ?props.overlayText: 'Requesting...'}
                                    </h6>

                                    <br />

                                    <BarLoader color="#4876ec" />

                                </div>


                            </LoadingOverlay>}


                        </section>

                    </div>

                </div>
                
                {oneWithDepositBonus?.length > 0 && <>
                    <div style={{
                        position: 'fixed', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        zIndex: 999999999,
                        textAlign: 'center',
                        width: '600px',
                    }}>

                        <Lottie style={{ width: '100%' }}  animationData={animation2} loop={true} /> 
                        <div className="bg-anger text-center flexify" style={{position: 'relative', marginTop: -80, zIndex: 999999}}>
                            <button onClick={claimDepositBonus} className="btn btn-warning" style={{background: '#d5a93c', backgroundColor: '#d5a93c'}}>
                                {!loading && <span style={{fontSize: 12}}>CLAIM {oneWithDepositBonus?.[0]?.depositBonus} {oneWithDepositBonus?.[0]?.abbrRo}</span>}
                                {loading && <span style={{fontSize: 12}}>PLEASE WAIT...</span>}
                            </button>
                        </div>
                
                    </div>

                </>}



            </MobileBreaker> 
            
            {(site_settings?.site_settings?.livechat_sitewide_floating_icon ?? 0) == 1 && <ChatDelegator />}


        </HelmetProvider>
    );
}

export default SiteWide;


