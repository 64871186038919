import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';



function FullScreenInputHolder(props) {

    const systemColorManager = systemColor()


    return (
        <div className="row purple-rain-bg-1" style={{minHeight: '100vh'}}>
            {/* <div className="col-1 col-md-1"></div> */}
            <div className="col-12 col-md-12">
                {props.children}
            </div>
        </div>

    );

}

export default FullScreenInputHolder;