import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import {authActions, withdrawalActions} from '../../redux/actions';





export const useReferralHook =  () => {
    
    const dispatch = useDispatch();
    const userData = useSelector(userSelect);

    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loading, setLoading]     = useState([])
    
    
    const loadDownLines = (myrefid) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            
            setRefDataRaw(response?.data)

        }))

    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid);
    }, [])

    return refDataRaw

}
