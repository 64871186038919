import React, {useState} from "react";

import {WebLink, Images} from "../customComponents";
import { removeUserSession, setUserSession, setUserFinance, setUserToken} from '../../utility/CommonAuth';
import  {useHistory} from 'react-router-dom';
import Colors from '../../utility/Colors';
import {notify, money} from '../../utility/Helper1';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer } from 'react-toastify';
import APIRequests from '../../utility/APIRequests';
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';
import {systemColor} from '../../utility/styles';


import { ProSidebarProvider, Sidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent} from 'react-pro-sidebar';
import MobileBreaker from "./MobileBreaker";
import { AiFillFolderOpen } from "react-icons/ai";



    


function Navbar() {

    const systemColorManager = systemColor()

    const userData = useSelector(userSelect);
    const dispatch = useDispatch();
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const hiddenFileInput = React.useRef(null);

    const [uploadingAvatar, setUploadingAvatar]           = useState(false);
    const history                                       = useHistory();

    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        
        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
              URL.revokeObjectURL(output.src) // free memory
            }

        }


    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )

        
    }


    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }
    






    // menu-only is used to exclude the whole click surface from where can be clicked to close the menu
    // note that the menu that opens from the side covers the whole screen, backgroundcolor will reveal this
    //MobileBreaker is used to make the menu start from where the page started from. without it, no way to control where the menu starts from
    // any div from the whole covering that has 'menu-only' wount close the menu when clicked. Makes this behave as a normal side menu

    

    return (<div id="allMenu" className="ba-navbar" style={{}}>
            
            <MobileBreaker additonalStyle={{}}>
                <ProSidebarProvider> 
                    
                    <Sidebar rootStyles={{borderColor: 'transparent'}}>
           
                        <div className="menu-only" style={{...systemColorManager.side_menu_bg, minHeight: '100vh'}}>
                            
                            {/*<div className="ba-navbar-user" style={{backgroundColor: 'transparent'}}>
                                
                                <div className="menu-close" style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}}>
                                    <i className="la la-times" />
                                </div>
                                <div className="thumb">
                                    
                                    <LoadingOverlay
                                        active={uploadingAvatar}
                                        spinner
                                    >
                                        <Images style={{width: 45, height: 45, borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer im" onClick={progClickFileUpload} width="60px" source={((userData?.image ?? null) != null)?userData?.image:require('../../images/athlete1.png')} alt="avatar" />
                                        <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>

                                    </LoadingOverlay>

                                </div>
                                <div className="details">
                                    <h5 style={systemColorManager.txt_2}>{userData?.username ?? 'Welcome'}</h5>
                                    <p style={systemColorManager.txt_1}>{finance?.[0]?.type?money(finance?.[0]?.balance, finance?.[0]?.type) : 'XXXXXXXX'}</p>
                                </div>
                            </div>*/}

                            <div style={{height: 25}}/>

                            <Menu menuItemStyles={{
                                button: ({ level, active, disabled }) => {
                                  // if (level === 1) {
                                    return {
                                      color: systemColorManager.submenu_arrow,
                                      backgroundColor: active ? "#fff" : undefined,
                                      "&:hover": {
                                         backgroundColor: "rgba(0, 0, 0, 0.5)",
                                         borderRadius: "0px !important",
                                         fontWeight: "bold !important"
                                       },
                                    };
                                  // }
                                },
                              }}>

                                <MenuItem component={<WebLink to={'/'} />} className="my-menu-item" style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Home</span>
                                </MenuItem>

                                 <MenuItem component={<WebLink to={'/deposit'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Deposit</span>
                                </MenuItem>


                                <MenuItem component={<WebLink to={'/withdraw'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Withdraw</span>
                                </MenuItem>


                                <MenuItem component={<WebLink to={'/minery'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Miners</span>
                                </MenuItem>







                                {((site_settings?.operational_settings?.useBonus ?? 0) == 1
                                || (site_settings?.operational_settings?.useReferralBonus ?? 0) == 1
                                || (site_settings?.operational_settings?.useRebateBonus ?? 0) == 1)&& 1> 2 && <SubMenu className="my-submenu" iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>} label={<span className="h6" style={{...systemColorManager.txt_1}}>Bonus</span>} rootStyles={{backgroundColor: 'transparent'}}>

                                    

                                    {(site_settings?.operational_settings?.useBonus ?? 0) == 1 && <MenuItem component={<WebLink to={'/deposit-bonus'} />} rootStyles={{...systemColorManager.submenu_bg}}> 
                                        <span style={{color: '#000'}} className="h6">Deposit Bonus</span>
                                    </MenuItem>}



                                    {(site_settings?.operational_settings?.useReferralBonus ?? 0) == 1 && <MenuItem component={<WebLink to={'/referral-bonus'} />} rootStyles={{...systemColorManager.submenu_bg}}> 
                                        <span style={{color: '#000'}} className="h6">Referral Bonus</span> 
                                    </MenuItem>}


                                    {(site_settings?.operational_settings?.useRebateBonus ?? 0) == 1 && <MenuItem component={<WebLink to={'/rebate-bonus'} />} rootStyles={{...systemColorManager.submenu_bg}}> 
                                        <span style={{color: '#000'}} className="h6">Rebate Bonus</span> 
                                    </MenuItem>}


                                </SubMenu>}





{/* 
                                {((site_settings?.faq?.length ?? 0) != 0) && <MenuItem component={<WebLink to={'/faq'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>FAQ</span>
                                </MenuItem>} */}


                                {((site_settings?.site_settings?.privacy_policy ?? '') != '') && <MenuItem component={<WebLink to={'/privacy-policy'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Privacy Info.</span>
                                </MenuItem>}

                                {((site_settings?.site_settings?.term ?? '') != '') && <MenuItem component={<WebLink to={'/term'} />} style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}>Terms of Usage</span>
                                </MenuItem>}



                                {!(userData?.accountId ?? '') !='' && <MenuItem component={<WebLink to={'/signup'} />} className="menu-close" style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}> Register</span>
                                </MenuItem>}

                                {!(userData?.accountId ?? '') !='' && <MenuItem component={<WebLink to={'/signin'} />} className="menu-close" style={{marginBottom: 10}} iconFaked={<AiFillFolderOpen size={15} color={systemColorManager.menu_icon_color}/>}>
                                    <span className="h6" style={{...systemColorManager.txt_1}}> Sign In</span>
                                </MenuItem>}

                                <MenuItem />


                                
                            </Menu>

                        </div>

                    </Sidebar>


                </ProSidebarProvider>


            </MobileBreaker>

        </div>
    
    );
}


export default Navbar;