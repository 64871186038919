import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";
import SelectCurrency from "../customComponents/SelectCurrency";

import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, decimalCount, moneyStatus} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {systemColor} from '../../utility/styles';
import MobileBreaker from "../layouts/MobileBreaker";

import { AiOutlineWallet, AiOutlineReload, AiOutlineDelete, AiOutlineHistory, AiFillCloseCircle, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import CustomAgo from "../customComponents/CustomAgo";
import Countdown from 'react-countdown';
import './styles.css';

const amountSuggestion = [10, 20, 50, 100, 200, 500, 800, 1000];


function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);


    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const search = useLocation();
    const depositRequest = search?.state?.deposit;
    const systemColorManager = systemColor()


    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }

    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }

    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }





    useEffect(() => {
        getPendingPayments()
    }, [])


    const getPendingPayments = () => {
        
        // setloading(true)
        // setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {

            // notify(response.msg, response.code)
        }))

    }


    const uploadSelectedPayment = (file) => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }




    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);

    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        setloading(true)

        setLoadingText('Processing request...')

        dispatch(depositActions.onCreateDeposit({amount, currency: currencySelected.type}, "deposit/new", response => {
            if(response.code == 1){
                setAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }







    const deleteInv = (txn_id) => {

        if(getPendingPayment?.[0]?.status != 0){
            notify('Cannot be deleted as payment is being processed.', 0)
            return;
        }

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {

            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }else{

            }

            setloading(false)
        }))

    }


    const thePendingPayment = () => {

        let displayableAmount = getPendingPayment?.[0]?.useCryptoValue == 1?`${getPendingPayment?.[0]?.amountEnforcedToPay} ${getPendingPayment?.[0]?.abbr}`:`${getPendingPayment?.[0]?.amountEnforcedToPay} USD`
        let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
        let perfectWalletobj;
        let eachParam;
        const isACrypto = getPendingPayment?.[0]?.autoConfirm == 1
        const gatewayName = isACrypto?'BitBase Gateway':'iSyntec Gateway'

        if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
            let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
            eachParam = Object.keys(pmAddress.param);
            perfectWalletobj = pmAddress
        }
        
        const additionalInfo = getPendingPayment?.[0]?.additionalInfoMy ?? '';
        const bankDetails = additionalInfo != '' ? JSON.parse(additionalInfo) : {};

        return <>

            <div class="container" style={{padding: 0}}>
                <div class="wrapper wrapper-content animated fadeInRight">
                    
                    {!isACrypto && <div class="row">
                        
                        <div class="col-md-12">
                            <div class="payment-card">

                                <div className="flexify" style={{justifyContent: "flex-start"}}>
                                    {/* <i class="fa fa-cc-visa payment-icon-big text-success"></i> */}
                                    <Images width={50} src={require('../../images/menu-1.png')} />
                                </div>

                                <h2>
                                    {bankDetails?.accountNumber}
                                </h2>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <small>
                                            <strong className="text-dark">{bankDetails?.accountName}</strong>
                                        </small>
                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <small>
                                            <strong className="text-dark">{bankDetails?.bank}</strong>
                                        </small>
                                    </div>
                                </div>


                            </div>

                            

                        </div>
                        
                    </div>}


                    <div class="row">



                        <div class="col-lg-12">

                            <div class="ibox">
                                <div class="ibox-title h5 text-dark">
                                    <Images width={50} src={require('./isyntec.png')} />
                                    {gatewayName}
                                    
                                </div>
                                <div class="ibox-content">

                                    <div class="panel-group payments-method" id="accordion">

                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h6 class="panel-title">
                                                </h6>
                                            </div>
                                            <div id="collapseTwo" class="panel-collapse collapse in" style={{display: 'block'}}>
                                                <div class="panel-body">

                                                    <div class="row">
                                                        
                                                        <div class="col-md-12 text-dark">
                                                            <strong>Product:</strong>: Account Top-up <br />
                                                            <strong>Amount:</strong>: <span class="text-navy">{displayableAmount}</span> <br />
                                                            <strong>Ref Code:</strong>: <span class="text-navy">{getPendingPayment?.[0]?.txn_id}</span><br />
                                                            {isACrypto && <><strong>Tether Address:</strong>: <span class="text-navy" style={{fontSize: 13}}>{getPendingPayment?.[0]?.address}

                                                            <CopyToClipboard text={getPendingPayment?.[0]?.address}
                                                                onCopy={() => notify('Copied', 1)}>
                                                                <span style={{marginLeft: 5}} className="text-dark cursor-pointer">
                                                                    <AiOutlineCopy />
                                                                </span>
                                                            </CopyToClipboard>

                                                                </span><br /></>}
                                                            
                                                            {!isACrypto && <p className="text-dark" style={{marginTop: 10}}>
                                                                All payments must be made within a valid period or timeframe. 
                                                                <br />
                                                                You must include your payment ref code ({getPendingPayment?.[0]?.txn_id}) as payment reference/naration during payment transaction.
                                                            </p>}

                                                            {isACrypto && <p className="text-dark" style={{marginTop: 10}}>
                                                                <img src={getPendingPayment?.[0]?.qrCode} />

                                                            </p>}

                                                        </div>

                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {!isACrypto && <div class="col-md-12">

                            <form role="form" id="payment-form">
                                
                                <div class="ow">
                                    <label className="form-file-label" for="customFile">
                                        
                                        {getPendingPayment?.[0]?.status == 1 && <div style={{marginBottom: 10}} className="text-center">
                                            Your payment is on confirmation. Please wait a moment while it is finally approved.
                                        </div>}

                                        <div class="colxs-12 text-center flexify" style={{}}>

                                            

                                            <span class="btn btn-primary" type="submit">{getPendingPayment?.[0]?.status == 1?'Re-s':'S'}ubmit Pay</span>

                                            <input style={{opacity: 0, width: 10, position: 'absolute', top: -10, marginTop: 70, zIndex: -10}} onChange={(e) => uploadSelectedPayment(e.target.files[0])} type="file" className="form-file-input"
                                                    id="customFile"/>
                                        </div>

                                    </label>

                                </div>

                            </form>
                        </div>}


                    </div>
                    
                </div>
            </div>



        </>

    }




    const newPayment = () => {

        return <>


            <form onSubmit = {processDeposit} action="">
                

                <div style={{backgroundColor: currencySelected?.abbr == 'USDTTRC20'?'#fff':'#f7f8fa'}} class="alert alert-success" role="alert">
                    <h4 class="text-dark alert-heading">
                        {currencySelected.payment_note_headline}
                    </h4>
                    <hr />
                    <p className="text-dark">{currencySelected.payment_note_body}</p>
                </div>


                <div class="single-input-wrap">
                   
                    {/* <span className="new-input-label" style={{...systemColorManager.txt_1}}>Amount To Fund</span> */}

                    <div className="input-group">
                        <input type="text" placeholder="0.00" className="form-field form-control" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{...systemColorManager.borders_color, ...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                        
                        {amount!='' && <div className="input-group-append" style={{position: 'absolute', right: 2, top: 5, zIndex: 5}}>
                            <span onClick={() => setAmount('')} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1" style={{...systemColorManager.txt_1}}>
                                <AiFillCloseCircle />
                            </span>
                        </div>}

                    </div>
                </div>


                <div class="single-input-wrap" style={{marginBottom: 200}}>

                    <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                    Submit </button>
                
                </div>

            </form>


            
        </>

    }







    


    const toggleTheCurrency = () => {
        let newCurrency = finance?.filter(eachCurr => eachCurr.abbrRo != currencySelected.abbrRo)
        notify(`Showing ${newCurrency?.[0]?.name} account`, 1)
        setCurrencySelected(newCurrency?.[0])
    }

    const editIcon = <>
            <div className="cursor-pointer" style={{marginRight: 10}}>

                {getPendingPayment?.length > 0 && <div className="text-" sstyle={{position: 'absolute', top: -2, right: 15, cursor: 'pointer'}} onClick={() => window.confirm('Do you want to delete this card? This action is irreversible') && deleteInv(getPendingPayment?.[0]?.txn_id)}>
                    <AiOutlineDelete size={20} />
                </div>}


                {getPendingPayment?.length == 0 && <div onClick={toggleTheCurrency}>
                    <span style={{fontSize: 10, marginRight: 10}}>Change Currency</span> 
                    <Images src={currencySelected?.image} className="fa fa-spin" style={{width: 22, height: 22}} />
                </div>}

            </div>

            <WebLink to={'/deposit-records'} className="" style={{marginRight: 10}}>
                <AiOutlineHistory size={20} />
            </WebLink>
        </>



    return (

        <SiteWide additionalTopIcons={editIcon} ssssloading={loading} sssoverlayText={loadingText} title={"Deposit"}>
            
            <FullScreenInputHolder>

                <div style={{marginTop: 60}}>



                    {(getPendingPayment?.length ?? 0) == 0?newPayment():thePendingPayment()}

                    <SelectCurrency selectedObj={currencySelected} curArrayToLoop={finance} isOpen={currencyModal} closeCurrencyModal={toggleCurrencyModal} onSelectCurrency={updateCurrencySelected} />
                </div>
            </FullScreenInputHolder>

        </SiteWide>
    );
}

export default DepositHistory;


